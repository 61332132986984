.Topnavbar { grid-area: Topnavbar; }
.sidebar { grid-area: sidebar; }
.dashboardcontent { grid-area: dashboardcontent; }


.DashboardPage{
    display: grid;
    height: 100vh;


    grid-template:
    'sidebar Topnavbar Topnavbar' 10%
    'sidebar dashboardcontent dashboardcontent'
    'sidebar dashboardcontent dashboardcontent'/15% 85% ;

}

.Dashboard{
    width: 100%;
    height: 100%;
    padding: 2em 6em;
    display: flex;
    text-align: center;
    flex-direction: column;
  }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
position: relative;
width: 100%;
padding-right: 15px;
padding-left: 15px; }
.col-md-1 {
flex: 0 0 8.33333%;
max-width: 8.33333%; }
.col-md-2 {
flex: 0 0 16.66667%;
max-width: 16.66667%; }
.col-md-3 {
flex: 0 0 25%;
max-width: 25%; }
.col-md-4 {
flex: 0 0 33.33333%;
max-width: 33.33333%; }
.col-md-5 {
flex: 0 0 41.66667%;
max-width: 41.66667%; }
.col-md-6 {
flex: 0 0 50%;
max-width: 50%; }
.col-md-7 {
flex: 0 0 58.33333%;
max-width: 58.33333%; }
.col-md-8 {
flex: 0 0 66.66667%;
max-width: 66.66667%; }
.col-md-9 {
flex: 0 0 75%;
max-width: 75%; }
.col-md-10 {
flex: 0 0 83.33333%;
max-width: 83.33333%; }
.col-md-11 {
flex: 0 0 91.66667%;
max-width: 91.66667%; }
.col-md-12 {
flex: 0 0 100%;
max-width: 100%; }
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
.justify-content-start {
justify-content: flex-start !important; }

.justify-content-end {
justify-content: flex-end !important; }

.justify-content-center {
justify-content: center !important; }

.justify-content-between {
justify-content: space-between !important; }

.justify-content-around {
justify-content: space-around !important; }
.d-flex {
 display: flex !important; }

 .align-items-start {
align-items: flex-start !important; }

.align-items-end {
align-items: flex-end !important; }

.align-items-center {
align-items: center !important; }

.align-items-baseline {
align-items: baseline !important; }

.align-items-stretch {
align-items: stretch !important; }

.align-content-start {
align-content: flex-start !important; }

.align-content-end {
align-content: flex-end !important; }

.align-content-center {
align-content: center !important; }

.align-content-between {
align-content: space-between !important; }

.align-content-around {
align-content: space-around !important; }

.align-content-stretch {
align-content: stretch !important; }

.align-self-auto {
align-self: auto !important; }

.align-self-start {
align-self: flex-start !important; }

.align-self-end {
align-self: flex-end !important; }

.align-self-center {
align-self: center !important; }

.align-self-baseline {
align-self: baseline !important; }

.align-self-stretch {
align-self: stretch !important; }
.m-0 {
margin: 0 !important; }

.mt-0,
.my-0 {
margin-top: 0 !important; }

.mr-0,
.mx-0 {
margin-right: 0 !important; }

.mb-0,
.my-0 {
margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
margin-left: 0 !important; }

.m-1 {
margin: 0.25rem !important; }

.mt-1,
.my-1 {
margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
margin-left: 0.25rem !important; }

.m-2 {
margin: 0.5rem !important; }

.mt-2,
.my-2 {
margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
margin-left: 0.5rem !important; }

.m-3 {
margin: 1rem !important; }

.mt-3,
.my-3 {
margin-top: 1rem !important; }

.mr-3,
.mx-3 {
margin-right: 1rem !important; }

.mb-3,
.my-3 {
margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
margin-left: 1rem !important; }

.m-4 {
margin: 1.5rem !important; }

.mt-4,
.my-4 {
margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
margin-left: 1.5rem !important; }

.m-5 {
margin: 3rem !important; }

.mt-5,
.my-5 {
margin-top: 3rem !important; }

.mr-5,
.mx-5 {
margin-right: 3rem !important; }

.mb-5,
.my-5 {
margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
margin-left: 3rem !important; }
.m-6 {
margin: 4rem !important; }

.mt-6,
.my-6 {
margin-top: 4rem !important; }

.mr-6,
.mx-6 {
margin-right: 4rem !important; }

.mb-6,
.my-6 {
margin-bottom: 4rem !important; }

.ml-6,
.mx-6 {
margin-left: 4rem !important; }
.m-auto {
margin: auto !important; }

.mt-auto,
.my-auto {
margin-top: auto !important; }

.mr-auto,
.mx-auto {
margin-right: auto !important; }

.mb-auto,
.my-auto {
margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
margin-left: auto !important; }
.p-0 {
padding: 0 !important; }

.pt-0,
.py-0 {
padding-top: 0 !important; }

.pr-0,
.px-0 {
padding-right: 0 !important; }

.pb-0,
.py-0 {
padding-bottom: 0 !important; }

.pl-0,
.px-0 {
padding-left: 0 !important; }

.p-1 {
padding: 0.25rem !important; }

.pt-1,
.py-1 {
padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
padding-left: 0.25rem !important; }

.p-2 {
padding: 0.5rem !important; }

.pt-2,
.py-2 {
padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
padding-left: 0.5rem !important; }

.p-3 {
padding: 1rem !important; }

.pt-3,
.py-3 {
padding-top: 1rem !important; }

.pr-3,
.px-3 {
padding-right: 1rem !important; }

.pb-3,
.py-3 {
padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
padding-left: 1rem !important; }

.p-4 {
padding: 1.5rem !important; }

.pt-4,
.py-4 {
padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
padding-left: 1.5rem !important; }

.p-5 {
padding: 3rem !important; }

.pt-5,
.py-5 {
padding-top: 3rem !important; }

.pr-5,
.px-5 {
padding-right: 3rem !important; }

.pb-5,
.py-5 {
padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
padding-left: 3rem !important; }
.text-left {
text-align: left !important; }

.text-right {
text-align: right !important; }

.text-center {
text-align: center !important; }
.flex-column {
flex-direction: column !important; }
.font-10 {
font-size: 10px; }

.font-12 {
font-size: 12px; }

.font-14 {
font-size: 14px; }

.font-16 {
font-size: 16px; }

.font-18 {
font-size: 18px; }

.font-20 {
font-size: 20px; }

.font-22 {
font-size: 22px; }

.font-24 {
font-size: 24px; }

.font-10 {
font-size: 10px; }

.font-12 {
font-size: 12px; }

.font-14 {
font-size: 14px; }

.font-16 {
font-size: 16px; }

.font-18 {
font-size: 18px; }

.font-20 {
font-size: 20px; }

.font-22 {
font-size: 22px; }

.font-24 {
font-size: 24px; }
.text-white {
color: #fff !important; }
.card {
position: relative;
display: flex;
flex-direction: column;
min-width: 0;
word-wrap: break-word;
background-color: #fff;
background-clip: border-box;
border: 0px solid rgba(0, 0, 0, 0.125);
border-radius: 4px; }
.card > hr {
    margin-right: 0;
    margin-left: 0; }
.card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }

.card-body {
flex: 1 1 auto;
min-height: 1px;
padding: 1.25rem; }

.card-title {
margin-bottom: 0.75rem; }

.card-subtitle {
margin-top: -0.375rem;
margin-bottom: 0; }

.card-text:last-child {
margin-bottom: 0; }

.card-link:hover {
text-decoration: none; }

.card-link + .card-link {
margin-left: 1.25rem; }

.card-header {
padding: 0.75rem 1.25rem;
margin-bottom: 0;
background-color: rgba(0, 0, 0, 0.03);
border-bottom: 0px solid rgba(0, 0, 0, 0.125); }
.card-header:first-child {
    border-radius: 4px 4px 0 0; }
.card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
padding: 0.75rem 1.25rem;
background-color: rgba(0, 0, 0, 0.03);
border-top: 0px solid rgba(0, 0, 0, 0.125); }
.card-footer:last-child {
    border-radius: 0 0 4px 4px; }

.card-header-tabs {
margin-right: -0.625rem;
margin-bottom: -0.75rem;
margin-left: -0.625rem;
border-bottom: 0; }

.card-header-pills {
margin-right: -0.625rem;
margin-left: -0.625rem; }

.card-img-overlay {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
flex-shrink: 0;
width: 100%; }

.card-img,
.card-img-top {
border-top-left-radius: 4px;
border-top-right-radius: 4px; }

.card-img,
.card-img-bottom {
border-bottom-right-radius: 4px;
border-bottom-left-radius: 4px; }

.card-deck .card {
margin-bottom: 15px; }