.login-submit {
  background: #427fc1;
  width: 244px;
  height: 55px;
  filter: drop-shadow(0px 4px 4px #4d76a2);
}

@media only screen and (max-width: 1366px) {
  .login-button {
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .col-md-8 {
    max-width: unset !important;
  }
  h3 {
    font-size: 12px;
  }
  .login-page .welcome.mt-5,
  .login-page .login-form-section.mt-5 {
    margin-top: unset !important;
  }
  .login-sub-header {
    padding: unset !important;
    font-size: 12px;
  }
  .login-header {
    line-height: unset !important;
  }
  .login-form-section.w-70 {
    width: unset !important;
  }
  .login-form-section input {
    height: 1em !important;
  }
  .login-label {
    font-size: 12px;
  }
  .login-form-group.mb-4 {
    margin-bottom: unset !important;
  }
  .login-page .vh-70 {
    height: 92vh !important;
  }
  .login-button .login-form-group {
    margin-top: unset !important;
    padding: unset;
  }
  .login-button .login-subsmit-font {
    height: unset;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .login-button h4 {
    font-size: 18px;
  }
}
