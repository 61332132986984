@media only screen and (max-width: 1200px) {
  .gametypedata {
    grid-auto-flow: row;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .summary-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .summary-totals {
    margin: 0.3em 0.3em;
  }
}
