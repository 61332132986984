.clock__variant--minimal {
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0.3em 1em;
  border-radius: 0.2em;
  font-weight: 600;
  color: #666;
}
.clock__variant--full {
  background-color: #3b78b9;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.clock__variant--full--text {
  font-size: 0.8rem;
  padding-left: 0.2em;
  font-family: 'Inter';
  font-weight: 600;
  white-space: pre;
  align-content: center;
}
