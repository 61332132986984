.errormain-wrapper {
  background-color: #bcd7f5;
  height: 100%;
  z-index: -1;
}
.errormain-responsive {
  display: flex;
  height: 92vh;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.error-innercontainer {
  background-color: #eaf3ff;
  padding: 11em 16em;
  border-radius: 0.5em;
  text-align: center;
  justify-content: center;
  display: grid;
  grid-template-rows: 2em 2em;
  gap: 1em;
}
.error-message {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1rem;
  color: #427fc1;
}
.error-backbutton {
  background-color: #1976d2;
  padding: 1em 2.4em;
  font-size: 1.1rem;
}
