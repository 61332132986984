/* To add to assets css files */
.gamelimits-wrapper {
  display: grid;
  grid-template-rows: 1fr, 1fr;
}
.header {
  color: white;
  font-family: 'Roboto';
  font-weight: 700;
  background-color: #87add4;
  width: 100%;
  padding: 1em;
}

.uppertable {
  display: grid;
  grid-template-rows: 1fr 5fr;
}
.globallimit,
.createindividuallimit {
  background-color: #93c1f2;
  width: auto;
  font-family: 'Roboto';
}
.globallimit {
  padding-bottom: 1em;
}
.globallimit-content {
  background: #ccd8e5;
  padding: 0.5em;
  margin: 0.2em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.global-limit--edit-button {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  background: #427fc1;
  filter: drop-shadow(0px 4px 4px #4d76a2);
  margin-left: 0.5em;
  padding: 0.6em 1.5em;
}
.createindividuallimit-headertext {
  padding: 0.3em 1em;
}
.createindividuallimit-content {
  font-family: 'Inter';
  font-size: 0.9em;
  background: #ccd8e5;
  width: 100%;
  align-content: center;
  padding: 2em;
  padding-bottom: 3em;
}
.createindividuallimit-wrapper {
  background: #ccd8e5;
}
.createindividuallimit-column2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.createindividuallimit-content1 {
  font-family: 'Inter';
  font-size: 0.9em;
  background: #ccd8e5;
  width: 100%;
  margin-top: 2vh;
  gap: 1em;
  align-content: center;
  padding-top: 0.5em;
  padding-left: 2em;
  padding-right: 2em;
}
.createindividuallimit-content2 {
  font-family: 'Inter';
  font-size: 0.9em;
  background: #ccd8e5;
  width: 100%;
  padding: 1em;
  gap: 1em;
  align-content: center;
  padding: 2em;
  padding-bottom: 1.5em;
}
.createindividuallimit-submit {
  font-family: 'Raleway';
  color: #ffffff;
  font-weight: 700;
  background: #427fc1;
  width: 16rem;
  height: 5rem;
  font-size: 1.2em;
}
.createindividuallimit-limit,
.createindividuallimit-combination {
  display: grid;
}
.limitcontenttable {
  background-color: #93c1f2;
  padding: 2em 1em;
}
#game-drawtime-limit-dt-select {
  min-height: 2.438em;
}
