opstats-page-container {
  width: 100%;
}
.opstats-page-header {
  background-color: #87add4;
  color: #fff;
  width: 100%;
  font-family: 'Roboto';
  padding: 0.8em 1em;
  font-weight: 600;
  letter-spacing: 0.0075em;
  text-transform: uppercase;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #87add4;
  padding: 0.3em 0em;
  font-size: 1em;
}
.sold-out-values-container {
  width: 100%;
}
.soldout-gametype {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
}
.gametype-1 {
  background-color: #bec4a3;
}
.gametype-2 {
  background-color: #d4a09d;
}
.soldout-gametype-header {
  color: rgb(68, 68, 68);
  font-family: Roboto;
  width: 100%;
  padding: 0.3em 0em;
  margin-bottom: 0em;

  border-top: 0.08em solid rgba(165, 165, 165, 0.5);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}
.sold-out-numbers-container {
  height: 10vh;
}
.sold-out-numbers-headercells {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}
.sold-out-cell {
  border: rgb(179, 179, 179) solid 0.1em;
  width: 1fr;
  padding: 0.8em auto;
  font-family: 'Roboto';
  font-weight: 600;
  color: #999;
}
.opstats-table-1 {
  background-color: #c5d8ec;
  padding: 1em 1em;
}
.soldout-headercells {
  text-align: center;
  font-family: 'Roboto';
  font-size: 0.95em;
  color: #333;
  border: #999 solid 0.1em;
  padding: 0.2em 0em;
}
.ncom-header {
  background-color: #fff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}
.ncom-gametype {
  width: 100%;
  background-color: white;
  height: 80vh;
  border: #999 solid 0.1em;
}
.opstats-tables-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.sold-out-header {
  background-color: hsl(210, 40%, 57%);
  color: white;
  padding: 1.4em 1em;
  font-family: 'Roboto';
  font-weight: 800;
}
.sold-out-table {
  border: 0.08em solid #bbb inset;
}
#select-small {
  padding: 4.5px 14px;
}
