.top-navbar {
  background: #4295e7;
  color: aliceblue;

  font-family: 'Raleway';
  font-weight: 600;

  height: 4rem;
  width: 100%;
  z-index: -1;
  display: grid;
  grid-template-columns: 6fr 1fr;
}
content-container {
  display: inline-flex;
}
.nav-user {
  background-color: #427fc1;
  height: 4em;
  color: #d6e9fc;
}
.nav-user:hover {
  color: #fff;
}

.sidebar-headings {
  font-family: 'Raleway';
  text-transform: uppercase;
  font-size: 1em;
}
.sidebar-collapse {
  display: grid;
  height: 4rem;
  grid-template-columns: 2.5fr 1fr;
}
.collapse-button {
  height: 4rem;
  background-color: #4295e7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.css-1ujnqem-MuiTabs-root {
  margin: 0;
}
.hide-menu {
  height: 12vh;
}

@media only screen and (min-width: 1200px) {
  .sidebarnew {
    position: fixed;
    background-color: #2783e4;
    color: rgb(255, 255, 255);
    height: 100%;
    margin-top: -4rem;
    box-shadow: 3px 4px 6px 2px rgba(40, 40, 40, 0.3);
    z-index: 3;
    max-width: 20%;
    font-size: 0.85em;
  }
  .sidebar-responsive {
    height: 100vh;
    max-width: 20vw;
  }
  .user-details {
    line-height: 1.45em;
  }
  .header-role {
    font-size: 0.85em;
  }
}
@media only screen and (max-width: 1200px) {
  /* .MuiBackdrop-root {
    width: 70%;
  } */
  .app-wrapper {
    width: 99.9%;
  }

  /* .MuiPaper-root {
    margin-left: -10%;
  } */

  /* .MuiButtonBase-root {
    width: 100%;
  } */
  /* .top-navbar {
    width: 70%;
  } */
  .page-content {
    width: 98.5%;
    /* margin-left: 1.5%; */
  }

  .sidebarnew {
    position: fixed;
    background-color: #2783e4;
    /* color: rgb(73, 73, 73); */
    color: rgb(255, 255, 255);
    height: 100%;
    margin-top: -4rem;
    box-shadow: 3px 4px 6px 2px rgba(40, 40, 40, 0.3);
    z-index: 3;
    min-width: 1vw;
  }
  /* .sidebar-responsive {
    height: 100vh;
    width: 80%;
  } */
  .sidebar-collapse {
    grid-template-columns: 3fr 1fr;
  }

  .sidebarnew {
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 1040px) {
  .app-wrapper {
    width: 99.9%;
  }

  .MuiPaper-root {
    /* margin-left: -10%; */
    width: 200px;
  }

  .nav-user {
    width: 103%;
  }

  .page-content {
    width: 97.5%;
    /* margin-left: 4.5%; */
  }

  .sidebarnew {
    overflow-y: scroll;
  }
}
