/*   CSS RESETTERS */
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
ul {
  padding: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}
/* IMPORTS */
@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Inter');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700');

html {
  scrollbar-color: #2278d4 #40464d;
}
/**/
body {
  width: 100%;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;

  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2278d4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2783e4;
}

/* Zediek css */

/* Icons */
@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 100 700;
  src: url('../icons/google_icons.woff2') format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* Fonts */
@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
}

/* Background Colors */
.bg-none {
  background-color: none !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-white {
  background-color: #ffffff !important;
}
.bg-black {
  background-color: #000000 !important;
}
.bg-gray {
  background-color: #f5f5f5 !important;
}
.bg-gray-dark {
  background-color: #2f363d !important;
}
.bg-gray-light {
  background-color: #e5e5e5 !important;
}
.bg-gray-lighter {
  background-color: #f8f8f8 !important;
}
.bg-gray-lightest {
  background-color: #fafafa !important;
}
.bg-red {
  background-color: #e53935 !important;
}
.bg-red-dark {
  background-color: #212121 !important;
}
.bg-red-light {
  background-color: #fbe9e7 !important;
}
.bg-red-lighter {
  background-color: #fff5f5 !important;
}
.bg-red-lightest {
  background-color: #fffafa !important;
}
.bg-orange {
  background-color: #e65100 !important;
}
.bg-orange-dark {
  background-color: #212121 !important;
}
.bg-orange-light {
  background-color: #fff3e0 !important;
}
.bg-orange-lighter {
  background-color: #fff7f1 !important;
}
.bg-orange-lightest {
  background-color: #fffaf9 !important;
}
.bg-yellow {
  background-color: #fdd835 !important;
}
.bg-yellow-dark {
  background-color: #212121 !important;
}
.bg-yellow-light {
  background-color: #fffde7 !important;
}
.bg-yellow-lighter {
  background-color: #fff9f2 !important;
}
.bg-yellow-lightest {
  background-color: #fffdf2 !important;
}
.bg-green {
  background-color: #4caf50 !important;
}
.bg-green-dark {
  background-color: #212121 !important;
}
.bg-green-light {
  background-color: #e8f5e9 !important;
}
.bg-green-lighter {
  background-color: #f1f8f3 !important;
}
.bg-green-lightest {
  background-color: #f9f9f9 !important;
}
.bg-blue {
  background-color: #2196f3 !important;
}
.bg-blue-dark {
  background-color: #212121 !important;
}
.bg-blue-light {
  background-color: #e3f2fd !important;
}
.bg-blue-lighter {
  background-color: #ebf8fe !important;
}
.bg-blue-lightest {
  background-color: #eceff4 !important;
}
.bg-curious-blue {
  background-color: #427fc1 !important;
}
.bg-indigo {
  background-color: #3f51b5 !important;
}
.bg-indigo-dark {
  background-color: #212121 !important;
}
.bg-indigo-light {
  background-color: #e8eaf6 !important;
}
.bg-indigo-lighter {
  background-color: #e8eaf6 !important;
}
.bg-indigo-lightest {
  background-color: #eceff4 !important;
}
.bg-purple {
  background-color: #9c27b0 !important;
}
.bg-purple-dark {
  background-color: #212121 !important;
}
.bg-purple-light {
  background-color: #e8eaf6 !important;
}
.bg-purple-lighter {
  background-color: #e8eaf6 !important;
}
.bg-whisper {
  background-color: #eeeeee !important;
}
.bg-picton-blue {
  background-color: #669dd7 !important;
}

/* Text Color */
.tc-black {
  color: #000000 !important;
}
.tc-white {
  color: #ffffff !important;
}
.tc-gray {
  color: #f5f5f5 !important;
}

/* width and height */
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-5 {
  height: 5% !important;
}
.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}
.h-25 {
  height: 25% !important;
}
.h-30 {
  height: 30% !important;
}
.h-35 {
  height: 35% !important;
}
.h-40 {
  height: 40% !important;
}
.h-45 {
  height: 45% !important;
}
.h-50 {
  height: 50% !important;
}
.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-75 {
  height: 75% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  height: 90% !important;
}
.h-95 {
  height: 95% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}

/* cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

/* image */
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

/* Borders */
.border {
  border: 1px solid #dee2e6;
}
.border-0 {
  border: 0;
}
.border-top {
  border-top: 1% solid #dee2e6;
}
.border-right {
  border-right: 1% solid #dee2e6;
}
.border-bottom {
  border-bottom: 1% solid #dee2e6;
}
.border-left {
  border-left: 1% solid #dee2e6;
}
.border-top-0 {
  border-top: 0;
}
.border-right-0 {
  border-right: 0;
}
.border-bottom-0 {
  border-bottom: 0;
}
.border-left-0 {
  border-left: 0;
}

.border-primary {
  border-color: #007bff;
}
.border-secondary {
  border-color: #6c757d;
}
.border-success {
  border-color: #28a745;
}
.border-info {
  border-color: #17a2b8;
}
.border-warning {
  border-color: #ffc107;
}
.border-danger {
  border-color: #dc3545;
}
.border-light {
  border-color: #f8f9fa;
}

/* lOGIN */
.login-page {
  background: #427fc1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* padding: 2em 6em; */
  display: flex;
  text-align: center;
  flex-direction: column;
}
.ball-20 {
  top: -26%;
  left: -16%;
  display: flex;
  position: absolute;
  object-fit: cover;
}
.ball-09 {
  top: 73.4%;
  left: 1.5%;
  display: flex;
  position: absolute;
  object-fit: cover;
}
.ball-16 {
  top: 75%;
  left: 83%;
  display: flex;
  position: absolute;
  object-fit: cover;
}
.login-container {
  /* top: 6%; */
  left: -2.3%;
  position: absolute;
  width: 105%;
  /* align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center; */
}
.login-logo-container {
  background: #9cc8f8;
  opacity: 0.9;
  /* left: -8.3%;
  height: 76%; */

  display: flex;

  height: 76%;
  /* width: 30%;  */
}
.login-logo {
  max-width: 100%;
  height: auto;
  mix-blend-mode: luminosity;
  object-fit: contain;
}
.login-form-container {
  background: #ffffff;
  opacity: 0.8;
}
.login-date-time-container {
  background-color: #a8cbf0;
  position: relative;
  width: 100%;
}

.login-date-time {
  text-align: right;
  font-weight: bold;
  font-size: 19px;
}

.login-form-content {
  padding: 4%;
  text-align: left;
}

.login-header {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 42px;
  color: #282828;
}

.login-form-group {
  margin-top: 3%;
  padding: 1%;
}
.login-label {
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 24px;
  color: #282828;
}

.login-submit-font {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */

  color: #ffffff;
}

.error-message {
  color: #ff0000;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  display: inline !important;
  /* identical to box height */
}

.span-icon {
  margin-top: 10px;
  /* identical to box height */
}

/* @media screen and (max-width: 1592px) {
  .login-page{
    width: 107%;
    height: 107%;
  }
}

@media screen and (max-width: 1366px) {
  .login-page{
    width: 109%;
    height: 109%;
  }
<<<<<<< HEAD

} */

.login-ball-20 {
  position: absolute;
  width: calc(100vw / 3);
  height: calc(100vw / 3);
  left: -12vw;
  top: -10vw;
}
.login-ball-16 {
  right: -2vw;
  position: absolute;
  bottom: -7vw;
  width: calc(100vw / 5);
  height: calc(100vw / 5);
}
.login-ball-9 {
  left: 2vw;
  bottom: 2vw;
  position: absolute;
  width: calc(100vw / 9);
  height: calc(100vw / 9);
}

.login-post {
  background: #9cc8f8;
  opacity: 0.9;
  flex: 0 0 41.6250033%;
  max-width: 41.6250033%;
  align-items: center;
  padding-left: 1.2em;
}
.login-post-img {
  mix-blend-mode: luminosity;
  width: calc(100vw / 4);
  height: calc((100vw / 4)-50px);
  display: flex;
  margin: auto;
}
.login-form-header {
  background: #9cc8f8;
  font-family: 'Roboto';
  font-weight: bold;
  color: #282828;
}
.login-form {
  background: #ffffff;
  opacity: 0.8;
}
.vh-70 {
  height: 80vh;
}
.h-90 {
  height: 90;
}
.login-sub-header {
  margin-top: 1%;
  padding: 1%;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 23px;

  color: #282828;
}
.login-input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  padding: 0.5%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #282828;
}
@media only screen and (max-height: 691px) {
  .login-page .login-form-section {
    margin-top: 20px !important;
  }
}

/* Dashboard Overview */
/* .cash-header{
  background-color: transparent;
} */
.ReactModalPortal .ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  overflow: scroll;
  z-index: 60;
}

button {
  border-style: none;
  filter: drop-shadow(0px 4px 4px #4d76a2);
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
}

/* UXUI Dinosaur Code */
.bmain-wrapper {
  font-family: 'Roboto';
  height: 100%;
  overflow-x: hidden;
}

.navbar {
  background: #17416d;
  position: absolute;
}
.bcontent {
  display: grid;
  grid-template-columns: 16% 84%;
}
.navbar-profile {
  background: #2278d4;
  color: #ffffff;
  padding: 1.2em;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bsidebar {
  background: #2783e4;
  height: 100%;
  position: sticky;
}

.profile-name {
  font-family: 'Roboto';
  font-weight: 700;
}
.profile-title {
  font-family: 'Raleway';
  font-weight: 400;
}
.bcontainer {
  padding: 2em;
}

/* To add to assets css files */

.header {
  color: white;
  font-family: 'Roboto';
  font-weight: 700;
  background-color: #87add4;
  width: 100%;
  padding: 1em;
}

.uppertable {
  display: grid;
  grid-template-columns: 49.89% 49.89%;
  gap: 0.2em;
}
.globallimit,
.createindividuallimit {
  background-color: #93c1f2;
  width: auto;
  font-family: 'Roboto';
}
.globallimit {
  padding: 1em;
}
.globallimit-content {
  background: #ccd8e5;
  padding: 1em;
  margin: 0.5em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.global-limit--edit-button {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  background: #427fc1;
  filter: drop-shadow(0px 4px 4px #4d76a2);
  margin-top: 1em;
  margin-left: 0.5em;
}

.createindividuallimit-content {
  font-family: 'Inter';
  font-size: 0.9em;
  background: #ccd8e5;
  width: 100%;
  margin-top: 2vh;
  padding: 1em;
  display: grid;
  gap: 1em;
  align-content: center;
  padding: 2em;
  padding-bottom: 3em;
}
.createindividuallimit-submit {
  font-family: 'Raleway';
  color: #ffffff;
  font-weight: 700;
  background: #427fc1;
}
.createindividuallimit-limit,
.createindividuallimit-combination {
  display: grid;
}
.limitcontenttable {
  background-color: #93c1f2;
  padding: 2em;
}
.Swertres {
  background-color: #17416d;
  height: 100vh;
  width: 100vw;
}
