.sc-bcXHqe.zIGoD {
  height: 150px !important;
  display: block;
}
.btnChooseFile span {
  margin: unset !important;
}
.btnChooseFile {
  background-color: white !important;
  padding: 10px 10px 13px 10px !important;
  min-width: unset !important;
}
