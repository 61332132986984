.topnavbar {
  grid-area: topnav;
  height: 100%;
  background-color: #17416d;
  display: grid;
  grid-template-columns: 6fr 1fr;
  max-height: 5em;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.sidebar {
  grid-area: sidebar;
  height: 100%;
  background-color: #2783e4;

  color: #ffffff;
  grid-template-rows: 1fr 8fr;
}
.sidebar-container {
  position: fixed;
  min-width: inherit;
  width: calc(100vw / 6);
}
.sidebar-header {
  width: calc(100vw / 6);
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 2em;
  background-color: #2278d4;
}
.sidebar-burger {
  background-color: #2278d4;
  position: fixed;
  margin: auto;
  width: calc(100vw / 20);
  padding: 0.5em 1em;
}
.header-name {
  font-family: 'Roboto';
  font-weight: 600;
}
.header-role {
  font-family: 'Raleway';
  font-weight: 500;
}
.dashboard-content {
  grid-area: dcontent;
  height: 100%;
  margin-top: 5em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  overflow: inherit;
  background-color: #e5f1ff;
}

.content-wrapper {
  display: grid;
  width: 100%;
  grid: 'sidebar dcontent' 12fr;
}

.sidebar-headings {
  font-family: 'Raleway';
  font-size: 0.9em;
  font-weight: 500;
  padding-top: 1.7em;
  padding-bottom: 0.7em;
  padding-left: 1em;
}
.sidebar-links {
  list-style-type: none;
  /* padding-right: 1em; */
  white-space: nowrap;
  line-height: 25px;
  font-size: 15px;
  margin-left: -0.2em;
}
.hide-menu {
  font-family: 'Inter';
}
.sidebar-link {
  padding: 0.3em;
  color: #ffffff;
  opacity: 1;
  border: 0.08em solid #c3cad3;
  border-radius: 2%;
  padding-left: 1.5em;
  letter-spacing: 0.08em;
  font-size: 1.05em;
  width: 100%;
  white-space: nowrap;
  margin-right: 1em;
}
.sidebar-link.active {
  border-radius: 4px;
  color: #17416d;
  font-weight: 700;
  background: linear-gradient(180deg, #afcae2 100%, #dcedff 100%);
  opacity: 1;
}
.sidebar-item {
  width: 100%;
  font-family: 'Roboto';
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.sidebar-item:active {
  background: linear-gradient(180deg, #ffffff 100%, #c5d8ec 100%);
  opacity: 1;
  border-radius: 5px;
  font-weight: 700;
}
.sidebar-link:hover {
  color: #17416d;
  background: #e3f2fd;
  fill-opacity: 0.2;
  font-weight: 600;

  margin-right: 0.5em;
  padding-right: 0.5em;
  border-radius: 5px;
}
.sidebar-responsive {
  height: 100vh;
}
.MuiTabScrollButton-root {
  opacity: 0.3;
  color: #000;
  background-color: #2278d4;
  background: #2278d4;
}
