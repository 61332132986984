.summary-header {
  background-color: #87add4;
  padding: 0.8em 2em;
}
.summary-totals {
  background-color: #87add4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.ResultsRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.8fr;
}
.resultCell {
  padding: 0.4em;
  padding-left: 1em;
  color: #555;

  font-family: 'Inter';
  font-weight: 600;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  margin: 0.1em 0.1em;
}
.resultCellNoResult {
  padding: 0.4em;
  padding-left: 1em;
  color: #a41818;

  font-family: 'Inter';
  font-weight: 600;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  margin: 0.1em 0.1em;
}
.summary-header {
  height: 7.8vh;
  align-items: center;
}
@media only screen and (min-width: 1200px) {
  .summary-header {
    flex-direction: 'row';
    justify-content: 'space-between';
  }
  .summary-content {
    background-color: #c5d8ec;
    height: 25vh;
    padding: 0.8em 2em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    padding: 1rem;
  }
  .gametypedata {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .resultscontainer {
    margin-top: 1em;
    background-color: #c5d8ec;
  }

  .ResultsHeader {
    padding: 0.4em;
    text-align: center;
    background-color: white;
    border-top: 0.08em solid rgba(165, 165, 165, 0.5);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Roboto';
    font-weight: 600;
    margin-bottom: 0.3em;
  }
}
