.on-click-input:focus{
    border: red;
    outline: none;
}
.on-click-input:hover{
    border: red;
}
.input-hidden{
    outline: none;
    border: none;
    text-indent: -9999em;
    text-shadow : 9999em 0 0 transparent;
}